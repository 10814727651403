<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="value"
        :label="label"
        prepend-icon="mdi-clock-outline"
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              v-model="hour"
              label="Hora"
              :items="hours"
            />
          </v-col>
          <v-col>
            <v-select
              v-model="minute"
              label="Minutos"
              :items="minutes"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
  export default {
    name: 'TimePicker',
    props: {
      value: {
        type: String,
        default: null,
      },
      label: {
        type: String,
        default: 'Time Picker',
      },
    },
    data () {
      return {
        menu: false,
        hours: [],
        minutes: [],
        hour: null,
        minute: null,
      }
    },
    computed: {
      time () {
        const hour = this.hour ? this.hour : '00'
        const minute = this.minute ? this.minute : '00'
        return `${hour}:${minute}`
      },
    },
    watch: {
      hour () {
        this.save()
      },
      minute () {
        this.save()
      },
    },
    beforeMount () {
      this.setHours()
      this.setMinutes()
    },
    methods: {
      setHours () {
        const hours = [...Array(24).keys()]
        this.hours = hours.map((x) => `${x}`.padStart(2, '0'))
      },
      setMinutes () {
        const minutes = [...Array(60).keys()]
        this.minutes = minutes.map((x) => `${x}`.padStart(2, '0'))
      },
      save () {
        this.$emit('input', this.time)
      },
    },
  }
</script>
